<template>
  <v-container class="container--fluid fill-height primary">
    <v-row
      no-gutters
      align="center"
      justify="center"
    >
      <v-col
        cols="12"
        sm="8"
        md="4"
        lg="4"
      >
        <v-card class="elevation-5 pa-3">
          <v-card-text>
            <v-col cols="12">
              <v-img
                src="https://res.cloudinary.com/datasmarty/image/upload/v1632484075/Datasmarty_Logo_icquyf.svg"
                height="95"
                width="100%"
              />
            </v-col>

            <v-col cols="12">
              <v-form>
                <v-text-field
                  v-model="model.email"
                  append-icon="mdi-account"
                  name="email"
                  :label="$t('login.email')"
                  type="email"
                  required
                />
                <v-text-field
                  v-model="model.password"
                  :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show2 ? 'text' : 'password'"
                  name="password"
                  :label="$t('login.password')"
                  required
                  autocomplete="current-password"
                  @click:append="show2 = !show2"
                />
              </v-form>
            </v-col>
          </v-card-text>
          <!--          <v-card-text>-->
          <!--            <v-row-->
          <!--              class="text-center"-->
          <!--              dense-->
          <!--              no-gutters-->
          <!--            >-->
          <!--              <v-col cols="12">-->
          <!--                <v-btn-toggle>-->
          <!--                  <v-btn-->
          <!--                    text-->
          <!--                    color="warning"-->
          <!--                    @click="setUserToLogin(0)"-->
          <!--                  >-->
          <!--                    <span>editor</span>-->
          <!--                  </v-btn>-->
          <!--                  <v-btn-->
          <!--                    text-->
          <!--                    color="warning"-->
          <!--                    @click="setUserToLogin(1)"-->
          <!--                  >-->
          <!--                    <span>admin</span>-->
          <!--                  </v-btn>-->
          <!--                </v-btn-toggle>-->
          <!--              </v-col>-->
          <!--            </v-row>-->
          <!--          </v-card-text>-->
          <v-card-actions>
            <!--            <localization />-->
            <!--            <v-btn icon>-->
            <!--              <v-icon color="blue">-->
            <!--                mdi-facebook-->
            <!--              </v-icon>-->
            <!--            </v-btn>-->
            <!--            <v-btn icon>-->
            <!--              <v-icon color="red">-->
            <!--                mdi-google-->
            <!--              </v-icon>-->
            <!--            </v-btn>-->
            <!--            <v-btn icon>-->
            <!--              <v-icon color="light-blue">-->
            <!--                mdi-twitter-->
            <!--              </v-icon>-->
            <!--            </v-btn>-->
            <v-spacer />
            <!--            <v-btn-->
            <!--              color="primary"-->
            <!--              outlined-->
            <!--              to="/singup"-->
            <!--            >-->
            <!--              {{ $t('login.singUp') }}-->
            <!--            </v-btn>-->
            <!--  <v-btn color="primary" outlined to="/singup">
              {{ $t("login.singUp") }}
            </v-btn> -->

            <v-btn
              color="primary"
              :loading="loading"
              @keyup.enter="login"
              @click="login"
            >
              {{ $t("login.singIn") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/* import Localization from '../widget/AppLocalization.vue';
 */
export default {
  name: 'SingIn',
  components: { /* Localization */ },
  data: () => ({
    loading: false,
    show3: false,
    show2: false,
    model: {
      email: '',
      password: '',
    },
  }),
  computed: {

  },
  watch: {

  },
  mounted() {
    window.addEventListener('keyup', (event) => {
      if (event.keyCode === 13) {
        this.login();
      }
    });
  },
  methods: {
    async login() {
      await this.$store.dispatch('LoginByEmail', {
        email: this.model.email,
        password: this.model.password,
      });
    },
  },
};
</script>
